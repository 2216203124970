import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

export const useBreakpoint = () => {
  const theme = useTheme();
  const [width, setWidth] = useState<number>(0);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    handleWindowSizeChange();

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const getDevice = useCallback(() => {
    if (width < parseInt(theme.device.tablet)) {
      return 'mobile';
    }
    if (width < parseInt(theme.device.desktop)) {
      return 'tablet';
    }
    return 'desktop';
  }, [theme, width]);

  const isMobile = useMemo(() => getDevice() === 'mobile', [getDevice]);
  const isTablet = useMemo(() => getDevice() === 'tablet', [getDevice]);
  const isDesktop = useMemo(() => getDevice() === 'desktop', [getDevice]);

  return { getDevice, isMobile, isTablet, isDesktop };
};
