import styled from 'styled-components';
export const Root = styled.div`
  border: none;
  color: ${props => props.theme.palette.text.primary};
  padding: 1rem;
  width: 300px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;