import FreestarAdSlot from '@freestar/pubfig-adslot-react-component';
import dynamic from 'next/dynamic';
import React, { PropsWithChildren } from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './AdFreestar.styles';
interface Props extends PropsWithClassName {
  style?: object;
  publisher: string;
  placementName: string;
  slotId?: string;
  targeting?: Record<string, string>;
  onNewAdSlotsHook?: () => void;
  onDeleteAdSlotsHook?: () => void;
  onAdRefreshHook?: () => void;
}
const AdFreestar: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): React.ReactElement => {
  const {
    publisher,
    placementName,
    slotId,
    targeting,
    style,
    onNewAdSlotsHook,
    onDeleteAdSlotsHook,
    onAdRefreshHook
  } = props;
  return <Root className={props.className} data-testid={'ad-freestar-root'} style={style} data-sentry-element="Root" data-sentry-component="AdFreestar" data-sentry-source-file="AdFreestar.tsx">
      <FreestarAdSlot publisher={publisher} placementName={placementName} slotId={slotId} targeting={targeting} onNewAdSlotsHook={onNewAdSlotsHook} onDeleteAdSlotsHook={onDeleteAdSlotsHook} onAdRefreshHook={onAdRefreshHook} data-sentry-element="FreestarAdSlot" data-sentry-source-file="AdFreestar.tsx" />
    </Root>;
};
export default dynamic(() => Promise.resolve(AdFreestar), {
  ssr: false
});